import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useGlobalState } from '../../../redux/globalState'
import Sidebar from './Sidebar';

function Header() {
  const { merchantDetails } = useGlobalState();

  const [sidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };


  return (
    <div>
      <Sidebar isOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
      <div className="top-bar">
        {/* leftside content */}
        <div className="welcome-to-pharma">
          Welcome to {merchantDetails.name}.
        </div>

        <div className="main-mobile-email-tag">

          <div className="top-mobile-email-tag">
            <img className="icon7" alt="" src="/icon.svg" />
            <div className="div13">{merchantDetails.mobileNumber}</div>
          </div>

          <a href={"mailto:" + merchantDetails.email} className="top-mobile-email-tag">
            <img className="icon7" alt="" src="/icon1.svg" />
            <div className="abcgmailcom">{merchantDetails.email}</div>
          </a>
        </div>
      </div>

      <div className="second-container">
        <div className="ham-dropdown">
          <img src="/menu.svg" onClick={toggleSidebar} />
        </div>

        <div className="second-container-logo">
          <img className="logo-style" src={merchantDetails?.logoImage?.actual} />
        </div>

        <div className="second-menu-container">
          <div className="about" onClick={() => window.open("/", "_self")}>Home</div>
          <div className="about" onClick={() => window.open("/about", "_self")}>
            About
          </div>
          <div className="about" onClick={() => window.open("/services", "_self")}>
            Services
          </div>
          <div className="about" onClick={() => window.open("/blogs", "_self")}>
            Blog
          </div>

          <div className="about" >
            Contact Us
          </div>
        </div>

        <div className="search-container">
          <div className="search">
            <img className="" alt="" src="/search.svg" />
          </div>
          <div>
            <button className="appointment-btn" onClick={() => window.open("/appointment", "_self")}>Book Appointment</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header

function navigate(arg0: string) {
  throw new Error('Function not implemented.');
}
