import { FunctionComponent, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import "./Page.css";

const Page: FunctionComponent = () => {
  const navigate = useNavigate();

  const onGoToHomepageClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onAboutUsTextClick = useCallback(() => {
    navigate("/about");
  }, [navigate]);

  const onServicesTextClick = useCallback(() => {
    navigate("/services");
  }, [navigate]);

  const onUitilityContainerClick = useCallback(() => {
    navigate("/404-page");
  }, [navigate]);

  const onFrameContainerClick = useCallback(() => {
    navigate("/appointment");
  }, [navigate]);

  const onContactUsTextClick = useCallback(() => {
    navigate("/contact-us");
  }, [navigate]);

  return (
    <div className="error-page">
        <div className="error-404">404</div>
        <b className="error-not-found">Oops! Page not found</b>
        <div className="error-desc">

          <h4>It looks like the page you're trying to reach doesn't exist. This could be due to several reasons:</h4>
          <div>
            The page might have been moved or deleted.
          </div>
          <div>
            The link you clicked on could be broken.
          </div>
          <div>
            The URL might have been mistyped.
          </div>          
        </div>
        <button className="go-to-homepage" onClick={()=>window.open("/", "_self")}>
            Go to homepage
        </button>
    </div>
  );
};

export default Page;
