import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./MedicalExperts.css";
import { useGlobalState } from "../../../redux/globalState";
import TextWithTags from "../../common/misc/TextWithTags";

const MedicalExperts: FunctionComponent = () => {
  const navigate = useNavigate();

  const {products, merchantDetails} = useGlobalState();
  const { id } = useParams<{ id: string }>();

  const [product, setProduct] = useState<any>({});

  useEffect(()=>{
    for(let pro of products){
      if(pro._id === id){
        setProduct(pro);
      }
    }
  })

  const onHomeTextClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onAboutUsTextClick = useCallback(() => {
    navigate("/about");
  }, [navigate]);

  const onServicesTextClick = useCallback(() => {
    navigate("/services");
  }, [navigate]);

  const onUitilityContainerClick = useCallback(() => {
    navigate("/404-page");
  }, [navigate]);

  const onContactUsTextClick = useCallback(() => {
    navigate("/contact-us");
  }, [navigate]);

  const onFrameContainerClick = useCallback(() => {
    const anchor = document.querySelector("[data-scroll-to='menuContainer']");
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  return (
    <div className="medical-experts2">


     
    </div>
  );
};

export default MedicalExperts;
