import React, { createContext, useReducer, useContext, ReactNode, Dispatch } from 'react';

interface State {
  token: string | null;
  merchantDetails: any;
  backgroundImages: any[];
  testimonies: any[];
  blogPosts: any[];
  products: any[];
  services: any[];
  categories: any[];
  isLoading: boolean
}

const initialState: State = {
  token: null,
  merchantDetails: {},
  backgroundImages: [],
  testimonies: [],
  blogPosts: [],
  products: [],
  services: [],
  categories: [],
  isLoading: false
};

type Action =
  | { type: 'SET_TOKEN'; payload: string }
  | { type: 'SET_MERCHANT_DETAILS'; payload: any }
  | { type: 'SET_BACKGROUND_IMAGES'; payload: any[] }
  | { type: 'SET_TESTIMONIES'; payload: any[] }
  | { type: 'SET_BLOG_POSTS'; payload: any[] }
  | { type: 'SET_PRODUCTS'; payload: any[] }
  | { type: 'SET_SERVICES'; payload: any[] }
  | { type: 'SET_CATEGORIES'; payload: any[] }
  | { type: 'SET_LOADING'; payload: boolean };

const GlobalStateContext = createContext<State | undefined>(undefined);
const GlobalDispatchContext = createContext<Dispatch<Action> | undefined>(undefined);

const globalReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_TOKEN':
      return { ...state, token: action.payload };
    case 'SET_MERCHANT_DETAILS':
      return { ...state, merchantDetails: action.payload };
    case 'SET_BACKGROUND_IMAGES':
      return { ...state, backgroundImages: action.payload };
    case 'SET_TESTIMONIES':
      return { ...state, testimonies: action.payload };
    case 'SET_BLOG_POSTS':
      return { ...state, blogPosts: action.payload };
    case 'SET_PRODUCTS':
      return { ...state, products: action.payload };
    case 'SET_SERVICES':
      return { ...state, services: action.payload };
    case 'SET_CATEGORIES':
      return { ...state, categories: action.payload };
    case 'SET_SERVICES':
      return { ...state, services: action.payload };
    case 'SET_LOADING':
      return { ...state, isLoading: action.payload };
    default:
      return state;
  }
};

interface GlobalProviderProps {
  children: ReactNode;
}

export const GlobalProvider = ({ children }: GlobalProviderProps) => {
  const [state, dispatch] = useReducer(globalReducer, initialState);

  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  );
};

export const useGlobalState = (): State => {
  const context = useContext(GlobalStateContext);
  if (context === undefined) {
    throw new Error('useGlobalState must be used within a GlobalProvider');
  }
  return context;
};

export const useGlobalDispatch = (): Dispatch<Action> => {
  const context = useContext(GlobalDispatchContext);
  if (context === undefined) {
    throw new Error('useGlobalDispatch must be used within a GlobalProvider');
  }
  return context;
};
