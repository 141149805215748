import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom';
import { useGlobalState } from '../../../redux/globalState';
import "./Footer.css"

function Footer() {

    const { merchantDetails, backgroundImages, products, categories } = useGlobalState();

    const navigate = useNavigate();
    const onBookAnAppointmentClick = useCallback(() => {
        navigate("/appointment");
      }, [navigate]);
    
      const onAboutUsTextClick = useCallback(() => {
        navigate("/about");
      }, [navigate]);
    
      const onServicesTextClick = useCallback(() => {
        navigate("/services");
      }, [navigate]);
    
      const onUitilityContainerClick = useCallback(() => {
        navigate("/404-page");
      }, [navigate]);
    
      const onContactUsTextClick = useCallback(() => {
        navigate("/contact-us");
      }, [navigate]);
      
  return (
    <div className="footer-section" style={{backgroundImage: "./background.svg"}}>
      <div className="footer-content-data">
        <div className="footer-data">
          <div className="footer-logo">
            <img className="footer-logo-image" alt=""  src={merchantDetails?.logoImage?.actual} />
          </div>
          <div className='footer-info-section'>
            <div className='footer-info'>
                <div className="footer-number">
                  <div className="bottom-vector-icon"> <img className="call-icon" alt="" src="/vector1.svg" /></div>
                  <div className="call-num">+91 9989741023</div>
                </div>
                <div className="footer-email">
                  <div className="bottom-vector-icon"> <img className="call-icon" alt="" src="/vector2.svg" /></div>
                  <div className="call-num">kjsdentalhospital@gmail.com</div>
                </div>
            </div> 
            <div className="footer-share-links">
                <div>
                  <a href="https://www.facebook.com/kjsdentalhospital/" target="_blank">
                    <img src="/fb2.svg" alt="KJS Dental Hospital facebook" />
                  </a>
                </div>
                <div>
                  <a href="https://www.instagram.com/kjsdentalhospital/" target="_blank">
                    <img src="/insta1.svg" alt="KJS Dental Hospital Instagram" />
                  </a>
                </div>
                <div>
                  <a href="https://x.com/KjsDental/" target="_blank">
                    <img src="/twiter2.svg" alt="KJS Dental Hospital twitter" />
                  </a>
                </div>
            </div>
          </div>   
        </div>
        <div className="footer-links-section">
          <div className='footer-desc'>
          A Simple Story About
          The Doctorate Medical Center & Health Care Foundation
          </div>
          <div className="footer-explore-links">
            <div className="whitespace"><b>Explore</b></div>
            <div className="about">Home</div>
            <div className="about" onClick={onServicesTextClick}>Services</div>
            <div className="about" onClick={onAboutUsTextClick}>About Us</div>
            <div className="about" onClick={onContactUsTextClick}>Contact Us</div>
            <div className="about">News</div>
          </div>
          <div className="footer-utility-links">
            <div className="whitespace"><b>Utility Pages</b></div>
            <div className="about" onClick={()=>window.open("/sitemap.xml", "_self")}>Sitemap</div>
            <div onClick={onUitilityContainerClick} className="about">404-page</div>
            <div onClick={onUitilityContainerClick} className="about">Changelog</div>
            <div onClick={onUitilityContainerClick} className="about">Licenses</div>
          </div>
          <div className="footer-book-appointment">
              <div className="whitespace"><b>Book An Appointment</b></div>
              <div className="text-footer">It is a long established fact that a reader will be distracted</div>
              <div>
                <input type="text" placeholder="your email address" className="appointment-input" />
              </div>
              <div>
                <button className="sb-btn">Submit</button>
              </div>
          </div>
        </div>
      </div>
      <div className='copyright'>
        <p>Copyright © {merchantDetails.name} | Designed by EWNS</p> 
      </div>
    </div>
  )
}

export default Footer