import { FunctionComponent, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import "./About.css";
import "../LandingPage/Landing.css";
import Footer from "../../common/misc/Footer";
import { useGlobalState } from "../../../redux/globalState";

const About: FunctionComponent = () => {

  const {merchantDetails} = useGlobalState();

  return (
    <div className="about1">
      <div className="banner">
        <img className="image-icon" alt="" src="/photo@2x.png" />
        <div className="image-text">

          <div className="img-subtext-two">
            About Us
          </div>
          <div className="img-subtext-three">
            Home {'>'} About Us
          </div>
          <div>

          </div>
        </div>
      </div>

      <div className="heading">



        <div className="title1">
          <div className="feel-like-home">
            Our team helps you get your life back on track.
          </div>

        </div>
        <div className="our-team-of7">Our team of highly trained professionals uses the latest healing technologies to restore you to pain-free health quickly and easily. We thoroughly evaluate & treat all of the contributing root factors related to your issue. 
          Includes, but is not limit, your work and home stressors</div>

      </div>

      <div className="banner">
        <img className="image-icon" alt="" src="/video@2x.png" />

      </div>

      <div className="about-us-container">
        <div className="about-us-content">
          <div className="departments">THE LEADERS</div>
          <div>We have the best Pharma & Medical</div>
          <div>Collaboratively administrate empowered markets via plug-and-play networks. Dynamically procrastinate B2C users after installed base benefits. Dramatically visualize customer.</div>
          <div>Completely synergize resource taxing relationships via premier niche markets. Professionally cultivate one-to-one customer service with robust ideas. Dynamically innovate.</div>

        </div>
        <div className="about-image">
          <div className="about-us-content-second">
            <div className="departments">OUR STORY</div>
            <div> Our mission is to help patients live better</div>
            <div>Collaboratively administrate empowered markets via plug-and-play networks. Dynamically procrastinate B2C users after installed base benefits. Dramatically visualize customer.</div>
            <div>Over 400 leading hospitals and clinics.</div>
            <div>
              Trusted doctors across 20+ specialties
            </div>
            <div>Award-winning practice management</div>
          </div>
        </div>
      </div>



      <div className="schedule-img-container">
          <div className="schedule-title">
            Online consultations with Certified doctors
          </div>
          <div className="schedule-section">
            <button className="schedule-btn-app" onClick={()=> window.open("/appointment", "_self")}>Book An Appointment</button>
            <button className="schedule-btn-call" onClick={()=> window.open("tel:" + merchantDetails.mobileNumber)}>Call: {merchantDetails.mobileNumber}</button>
          </div>
      </div>

      <div className="about-us-container">
        <div className="about-us-content">

          <div><h1>Our values that drive success</h1></div>



        </div>
        <div className="about-image">
          <div className="about-us-content-second">

            <div>Our team of highly trained professionals uses the latest healing technologies to restore you to pain-free health quickly and easily. We thoroughly evaluate & treat all of the contributing root factors related to your issue. Includes, but is not limit, your work and home stressors.</div>

          </div>
        </div>
      </div>


      <div className="frame-div-about-us">

        <div className="div14">
          <div className="sub-content">
            <div className="sub-content">
              <div className="title10">
                <div className="angioplasty">Bio Technology</div>

                <img className="title-child" alt="" src="/icon4.svg" />
              </div>
              <div className="our-team-of1">Our team of highly trained professionals uses the latest healing
                technologies.</div>
            </div>
          </div>
        </div>

        <div className="div14">
          <div className="sub-content">
            <div className="sub-content">
              <div className="title10">
                <div className="angioplasty">Vaccine</div>
                <img className="title-child" alt="" src="/icon5.svg" />
              </div>
              <div className="our-team-of1">Our team of highly trained professionals uses the latest healing
                technologies.</div>
            </div>

          </div>
        </div>

        <div className="div14">
          <div className="sub-content">
            <div className="sub-content">
              <div className="title10">
                <div className="angioplasty">Expert Doctors</div>
                <img className="title-child" alt="" src="/icon6.svg" />
              </div>
              <div className="our-team-of1">Our team of highly trained professionals uses the latest healing
                technologies.</div>
            </div>

          </div>
        </div>

        <div className="div14">
          <div className="sub-content">
            <div className="sub-content">
              <div className="title10">
                <div className="angioplasty">Latest Technology</div>
                <img className="title-child" alt="" src="/icon7.svg" />
              </div>
              <div className="our-team-of1">Our team of highly trained professionals uses the latest healing
                technologies.</div>
            </div>

          </div>
        </div>

      </div>
    </div>
  );
};

export default About;
