import React from 'react'
import "./Sidebar.css"
import { useGlobalState } from '../../../redux/globalState'
function Sidebar({isOpen, toggleSidebar } :any) {
    const {merchantDetails} = useGlobalState();
  return (
    <div className={`sidebar ${isOpen ? 'open' : ''}`}>
      <button className="close-button" onClick={toggleSidebar}>x</button>
      <nav>
        
      <div>
            <img  width={"120px"}  src={merchantDetails?.logo} />
          </div>
        <a href="/home" onClick={toggleSidebar}>Home</a>
        <a href="/about" onClick={toggleSidebar}>About</a>
        <a href="/services" onClick={toggleSidebar}>Services</a>
        <a href="/contact-us" onClick={toggleSidebar}>Contact</a>
      </nav>
    </div>
  )
}

export default Sidebar