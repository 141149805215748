import React from 'react'
import "./TestimonialCard.css"
function TestimonialCard({ name, role, testimonial, rating, imageUrl }: any) {
    return (
        <div className="testimonial-card">
      <div className="testimonial-header">
        <div className='testimont-profile'>
        <img src={imageUrl} alt={name} className="testimonial-avatar" />
        <div className="testimonial-info">
          <h3>{name}</h3>
          <p>{role}</p>
        </div>
        </div>
        <img src="/icon2.svg" alt='quote-mark' width={"25px"} />
      </div>
      <div className="testimonial-body">
        <p>{testimonial}</p>
      </div>
      <div className="testimonial-footer">
        {[...Array(rating)].map((_, index) => (
          <span key={index} className="star">&#9733;</span>
        ))}
      </div>
    </div>
      );
}

export default TestimonialCard