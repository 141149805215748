import { FunctionComponent, useState, useCallback, useEffect } from "react";
import Frame from "../../common/misc/Frame";
import PortalPopup from "../../common/misc/PortalPopup";
import { useNavigate } from "react-router-dom";
import Component1 from "../../common/misc/Component1";
import Component from "../../common/misc/Component";
import { subscribeUser } from "../../utils/api-service";
import "./Landing.css";
import { fetchDentalServices } from '../../utils/dentalserviceapi';
import DentalService from '../../types/dentalservicelist';
import TestimonialCard from "../../TestimonialCard/TestimonialCard";
import { useGlobalState } from "../../../redux/globalState";
import TextWithTags from "../../common/misc/TextWithTags";
import Header from "../../common/misc/Header";
import Sidebar from "../../common/misc/Sidebar";


const Landing: FunctionComponent = () => {
  const [isFrameOpen, setFrameOpen] = useState(false);
  const { merchantDetails, backgroundImages, products, categories, testimonies } = useGlobalState();
  const [dentalServices, setDentalServices] = useState<DentalService[]>([]);
  const [newsletterEmail, setNewsletterEmail] = useState("");
  const [error, setError] = useState<string | null>(null);

  // const testimonials = [{
  //   "_id": "66a403aea34e2f8d42eaee54",
  //   "isActive": true,
  //   "createdOn": "2024-07-26T20:14:38.978Z",
  //   "image": {
  //     "actual": "https://s3-ap-southeast-1.amazonaws.com/content-ewns/images/testimonialimages/66a4038ea34e2f8d42eaee53.jpeg",
  //     "large": "https://s3-ap-southeast-1.amazonaws.com/content-ewns/images/testimonialimages/large/66a4038ea34e2f8d42eaee53.jpeg",
  //     "medium": "https://s3-ap-southeast-1.amazonaws.com/content-ewns/images/testimonialimages/medium/66a4038ea34e2f8d42eaee53.jpeg",
  //     "small": "https://s3-ap-southeast-1.amazonaws.com/content-ewns/images/testimonialimages/small/66a4038ea34e2f8d42eaee53.jpeg"
  //   },
  //   "name": "Manish Kumar",
  //   "title": "Testimony 1",
  //   "description": "esglkse lkml knl jn onoi lmkl m kml km ion oin oi ni on oin oin noesglkse elkm lkml knl jn onoi lmkl m kml km ion oin oi ni on oin oin noesglkse elkm lkml knl jn onoi",
  //   "imageUrl": "https://s3-ap-southeast-1.amazonaws.com/content-ewns/images/testimonialimages/66a4038ea34e2f8d42eaee53.jpeg",
  //   "rating": 5,
  //   "username": "sribalajinovelties",
  //   "index": 1,
  //   "url": "/testimonials/testimony-1/1"
  // }, {
  //   "_id": "66a403aea34e2f8d42eaee54",
  //   "isActive": true,
  //   "createdOn": "2024-07-26T20:14:38.978Z",
  //   "image": {
  //     "actual": "https://s3-ap-southeast-1.amazonaws.com/content-ewns/images/testimonialimages/66a4038ea34e2f8d42eaee53.jpeg",
  //     "large": "https://s3-ap-southeast-1.amazonaws.com/content-ewns/images/testimonialimages/large/66a4038ea34e2f8d42eaee53.jpeg",
  //     "medium": "https://s3-ap-southeast-1.amazonaws.com/content-ewns/images/testimonialimages/medium/66a4038ea34e2f8d42eaee53.jpeg",
  //     "small": "https://s3-ap-southeast-1.amazonaws.com/content-ewns/images/testimonialimages/small/66a4038ea34e2f8d42eaee53.jpeg"
  //   },
  //   "name": "Manish Kumar",
  //   "title": "Testimony 1",
  //   "description": "esglkse lkml knl jn onoi lmkl m kml km ion oin oi ni on oin oin noesglkse elkm lkml knl jn onoi lmkl m kml km ion oin oi ni on oin oin noesglkse elkm lkml knl jn onoi",
  //   "imageUrl": "https://s3-ap-southeast-1.amazonaws.com/content-ewns/images/testimonialimages/66a4038ea34e2f8d42eaee53.jpeg",
  //   "rating": 5,
  //   "username": "sribalajinovelties",
  //   "index": 1,
  //   "url": "/testimonials/testimony-1/1"
  // }, {
  //   "_id": "66a403aea34e2f8d42eaee54",
  //   "isActive": true,
  //   "createdOn": "2024-07-26T20:14:38.978Z",
  //   "image": {
  //     "actual": "https://s3-ap-southeast-1.amazonaws.com/content-ewns/images/testimonialimages/66a4038ea34e2f8d42eaee53.jpeg",
  //     "large": "https://s3-ap-southeast-1.amazonaws.com/content-ewns/images/testimonialimages/large/66a4038ea34e2f8d42eaee53.jpeg",
  //     "medium": "https://s3-ap-southeast-1.amazonaws.com/content-ewns/images/testimonialimages/medium/66a4038ea34e2f8d42eaee53.jpeg",
  //     "small": "https://s3-ap-southeast-1.amazonaws.com/content-ewns/images/testimonialimages/small/66a4038ea34e2f8d42eaee53.jpeg"
  //   },
  //   "name": "Manish Kumar",
  //   "title": "Testimony 1",
  //   "description": "esglkse lkml knl jn onoi lmkl m kml km ion oin oi ni on oin oin noesglkse elkm lkml knl jn onoi lmkl m kml km ion oin oi ni on oin oin noesglkse elkm lkml knl jn onoi",
  //   "imageUrl": "https://s3-ap-southeast-1.amazonaws.com/content-ewns/images/testimonialimages/66a4038ea34e2f8d42eaee53.jpeg",
  //   "rating": 5,
  //   "username": "sribalajinovelties",
  //   "index": 1,
  //   "url": "/testimonials/testimony-1/1"
  // }, {
  //   "_id": "66a403aea34e2f8d42eaee54",
  //   "isActive": true,
  //   "createdOn": "2024-07-26T20:14:38.978Z",
  //   "image": {
  //     "actual": "https://s3-ap-southeast-1.amazonaws.com/content-ewns/images/testimonialimages/66a4038ea34e2f8d42eaee53.jpeg",
  //     "large": "https://s3-ap-southeast-1.amazonaws.com/content-ewns/images/testimonialimages/large/66a4038ea34e2f8d42eaee53.jpeg",
  //     "medium": "https://s3-ap-southeast-1.amazonaws.com/content-ewns/images/testimonialimages/medium/66a4038ea34e2f8d42eaee53.jpeg",
  //     "small": "https://s3-ap-southeast-1.amazonaws.com/content-ewns/images/testimonialimages/small/66a4038ea34e2f8d42eaee53.jpeg"
  //   },
  //   "name": "Manish Kumar",
  //   "title": "Testimony 1",
  //   "description": "esglkse lkml knl jn onoi lmkl m kml km ion oin oi ni on oin oin noesglkse elkm lkml knl jn onoi lmkl m kml km ion oin oi ni on oin oin noesglkse elkm lkml knl jn onoi",
  //   "imageUrl": "https://s3-ap-southeast-1.amazonaws.com/content-ewns/images/testimonialimages/66a4038ea34e2f8d42eaee53.jpeg",
  //   "rating": 5,
  //   "username": "sribalajinovelties",
  //   "index": 1,
  //   "url": "/testimonials/testimony-1/1"
  // }]

  const departments = [
    {
      "childCategories": null,
      "name": "Dental Hospital",
      "description": "Our team of highl professionals uses the latest heal echnologies health quickly and easily.",
      "isArchived": false,
      "username": "kjsdentalhospital",
      "createdOn": "2018-03-08T02:18:07.985Z",
      "_id": "5aa09d5f8d5dbc5188f2a39c",
      "isParent": true,
      "parentCategory": null,
      "vertical": null,
      "parentCategoryId": null,
      "image": "https://s3-ap-southeast-1.amazonaws.com/content-ewns/images/testimonialimages/66a4038ea34e2f8d42eaee53.jpeg",
      "slug": "category/Dental-Hospital/5aa09d5f8d5dbc5188f2a39c"
    }
  ]
  const navigate = useNavigate();

  const openFrame = useCallback(() => {
    setFrameOpen(true);
  }, []);

  const closeFrame = useCallback(() => {
    setFrameOpen(false);
  }, []);
  const onContainerClick = useCallback((_id: string) => {
    navigate("/medical-experts/" + _id);
  }, [navigate]);

  const onAboutUsTextClick = useCallback(() => {
    navigate("/about");
  }, [navigate]);

  const onHomeTextClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const handleSubscribe = async () => {

    let token = localStorage.getItem("_t");

    if (!token) {
      window.open("/login", "_self");
    }
    if (await subscribeUser(token, newsletterEmail)) {
      setNewsletterEmail("");
    }
  }

  return (
    <>
      <div className="landing">

        <div className="banner">
          <img className="image-icon" src={"/image@2x.png"} />

          <div className="image-text">
            <div className="img-subtext-one">
              {merchantDetails.name}
            </div>
            <div className="img-subtext-two">
              Makes Your Health Better<br /> Will Makes Us Better
            </div>
            <div className="img-subtext-three">
            </div>
            <div>
              <button className="appointment-btn" onClick={openFrame}>Book Appointment</button>
            </div>
          </div>
        </div>

        {categories.length > 0 && <><div className="heading">

          <div className="departments">departments</div>

          <div className="title1">
            <div className="feel-like-home">
              Feel Like Home With Best Medical Care
            </div>
          </div>
        </div>

          <div className="frame-div">
            {categories.map((item, index) => (
              <>
                <div className="div14" key={index}>
                  <div className="sub-content">
                    <div className="title10">
                      <div className="angioplasty">{item.name}</div>
                      <img className="title-child" alt="" src="/frame-2484.svg" />
                    </div>
                    <div className="our-team-of1"><TextWithTags text={item.description} /></div>
                  </div>
                </div>
                <div>
                  {item?.image && <img width={"100%"} height={"100%"} src={item.image}></img>}
                </div>
              </>
            ))}
          </div></>}

        <div className="about-us-container">
          <div className="about-us-content">
            <div className="departments">About Us</div>
            <TextWithTags text={merchantDetails.description} />
            <div className="about-us-status">
              <div className="about-status">
                <div><img src="/tick-mark.png" /></div>
                <div>20+ years of excellence</div>
              </div>
              <div className="about-status">
                <div><img src="/tick-mark.png" /></div>
                <div>20+ years of excellence</div>
              </div>
            </div>
            <div >
              <button onClick={onAboutUsTextClick} className="about-btn">Know more</button>
            </div>
          </div>
          <div className="about-image">
            <img className="img-width" src="/image.svg" />
          </div>
        </div>


        <div className="schedule-img-container">
          <div className="schedule-title">
            Schedule an imperson or virtual appointment today
          </div>
          <div className="schedule-section">
            <button className="schedule-btn-app" onClick={() => window.open("/appointment", "_self")}>Book An Appointment</button>
            <button className="schedule-btn-call" onClick={() => window.open("tel:" + merchantDetails.mobileNumber)}>Call: {merchantDetails.mobileNumber}</button>
          </div>
        </div>



        {
          testimonies.length > 0 && <>
            <div className="heading">
              <div className="departments">TESTIMONIALS</div>
            </div>
            <div className="testimoni-section">
              <div className="title-testimoni">Feedbacks about our service from the bottom of heart</div>

              <div className="testimonial-grid">
                {testimonies.map((testimonial, index) => (
                  <TestimonialCard
                    key={index}
                    name={testimonial.name}
                    role={testimonial.title}
                    testimonial={testimonial.description}
                    rating={testimonial.rating}
                    imageUrl={testimonial.imageUrl}
                  />
                ))}
              </div>
            </div>
          </>
        }


        <div className="team-container">
          <div className="text9">
            <div className="heading3">

              <div className="medical-experts">{`MEDICAL EXPERTs `}</div>

              <div className="title2">
                <div className="the-professional-doctors">
                  The Professional Doctors
                </div>
              </div>
            </div>
          </div>

          <div className="team-content">

            {products.map(product => {
              return (
                <div className="team-card" >
                  <div className="image1" onClick={() => onContainerClick(product._id)}>
                    <img className="image-icon5" alt="Dr. M Shylaja" src={product?.primaryImage?.actual} />
                  </div>
                  <div className="text10" onClick={() => onContainerClick(product._id)}>
                    <div className="dr-helen-wilmore">{product.name}</div>
                    <div className="dental-patient4">{product.category}</div>
                  </div>
                  <div className="follow">
                    <img className="insta-icon" alt="" src="/insta.svg" onClick={() => { window.open("https://instagram.com") }} />
                    <img className="fb-icon" alt="" src="/fb.svg" onClick={() => { window.open("https://facebook.com") }} />
                    <img className="twiter-icon" alt="" src="/twiter.svg" onClick={() => { window.open("https://x.com") }} />
                  </div>
                </div>)
            })}
          </div>



          <div className="line4" />


          <div className="counter-content">
            <Component1 prop="25" years="Years " experience="Experience" />
            <Component1
              prop="893"
              years="Medicament"
              experience=" Invented
"
              propWidth="161.9px"
            />
            <Component1
              prop="75"
              years="Awards"
              experience="Winned"
              propWidth="116.4px"
            />
            <Component1
              prop="673 k"
              years="Happy"
              experience=" Clients"
              propWidth="118.5px"
            />
            <Component1
              prop="751"
              years="Pharmacies"
              experience=" Partners"
              propWidth="160.4px"
            />
          </div>


        </div>

        <div className="joinus-section">

          <div className="departments">NEWSLETTER</div>

          <div className="joinus-title">
            JOIN US
          </div>


          <div className="subscribe-joinus">
            <input type="text" className="joinus-input" placeholder="Your Email..." value={newsletterEmail} onChange={(e) => setNewsletterEmail(e.target.value)} />
            <div className="subscribe-btn" onClick={handleSubscribe}>Subscribe</div>
          </div>
        </div>
      </div>

      {isFrameOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeFrame}
        >
          <Frame onClose={closeFrame} />
        </PortalPopup>
      )}
    </>
  );
};

export default Landing;
