// apiService.js
import axios from 'axios';
import { useGlobalDispatch } from '../../redux/globalState';

const axiosInstance = axios.create({
  baseURL: 'https://api.ewns.in',
  headers: {
    'Accept': 'application/json',
  }
});


const username = window.location.host && window.location.host.includes("localhost") ? "kjsdentalhospital" : window.location.host.split(".ewns.in")[0];

axiosInstance.interceptors.response.use(
  (response) => response, 
  (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem('_t');
    }
    return Promise.reject(error);
  }
);

// Method to get the token
export const fetchToken = async () => {

  const params = new URLSearchParams();
  params.append('grant_type', 'client_credentials');
  params.append('scope', 'api.read');
  params.append('client_id', 'ewnsthemes');
  params.append('client_secret', 'Laxmidevi@99');

  try {
    const response = await axios.post(
      'https://identityserver.ewns.in/connect/token',
      params,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );
    return response.data.access_token;
  } catch (error) {
    console.error('Error fetching token:', error);
    throw error;
  }
};

// Method to get merchant details
export const getMerchantDetails = async (token) => {
  try {
    const response = await axiosInstance.get('/api/Merchants/' + username, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching merchant details:', error);
    throw error;
  }
};

// Method to get background images
export const getBackgroundImages = async (token) => {
  try {
    const response = await axiosInstance.get('/api/Images/background/' + username, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching background images:', error);
    throw error;
  }
};

// Method to get testimonies
export const getTestimonies = async (token) => {
  try {
    const response = await axiosInstance.get('/api/Testimonys/' + username, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching testimonies:', error);
    throw error;
  }
};

// Method to get blog posts
export const getBlogPosts = async (token, skip = 0, limit = 10) => {
  try {
    const response = await axiosInstance.get(`/api/blogPosts/list/${username}?skip=${skip}&limit=${limit}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching blog posts:', error);
    throw error;
  }
};

// Method to get products
export const getProducts = async (token, skip = 0, limit = 10) => {
  try {
    const response = await axiosInstance.get(`/api/Products/${username}/list?skip=${skip}&limit=${limit}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching products:', error);
    throw error;
  }
};

// Method to get services
export const getServices = async (token, skip = 0, limit = 10) => {
  try {
    const response = await axiosInstance.get(`/api/Services/${username}/list?skip=${skip}&limit=${limit}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching services:', error);
    throw error;
  }
};


export const fetchServiceCount = async (token) => {
  const response = await axiosInstance.get(`/api/Services/${username}/count`, {
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  });
  return response.data;
};

export const fetchProductCount = async (token) => {
  const response = await axiosInstance.get(`/api/Products/${username}/count`, {
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  });
  return response.data;
};

export const fetchCategoriesTree = async (token) => {
  const response = await axiosInstance.get(`/api/Categories/tree/${username}`, {
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  });
  return response.data;
};

export const subscribeUser = async (token, email) => {
  const response = await axiosInstance.post(`https://api.ewns.in/api/Internal/subscribers`, {
    email: email,
    username: username
  }, {
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  });
  return response.data;
};