import { FunctionComponent, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Appointment.css";

const Appointment: FunctionComponent = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    date: '',
    time: '',
  });

  const handleChange = (e: any) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e:any) => {
    e.preventDefault();
    console.log('Form submitted:', formData);
  };
  return (
    <div className="appointment-container">
    <h2>Wide network of healthcare solutions</h2>
    <form className="appointment-form" onSubmit={handleSubmit}>
      <h3>APPOINTMENT</h3>
      <h1>Consult with Our Experts</h1>
      <div className="form-row">
        <input
          type="text"
          name="name"
          placeholder="Enter your name here"
          value={formData.name}
          onChange={handleChange}
          required
        />
        <input
          type="email"
          name="email"
          placeholder="Email address"
          value={formData.email}
          onChange={handleChange}
          required
        />
      </div>
      <div className="form-row">
        <input
          type="tel"
          name="phone"
          placeholder="Phone number"
          value={formData.phone}
          onChange={handleChange}
          required
        />
        <input
          type="date"
          name="date"
          value={formData.date}
          onChange={handleChange}
          required
        />
        <input
          type="time"
          name="time"
          value={formData.time}
          onChange={handleChange}
          required
        />
      </div>
      <button type="submit">Book Appointment Now</button>
      <p>Thank you for your booking. we will call you shortly</p>
    </form>
  </div>



  );
};

export default Appointment;
