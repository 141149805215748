import { MutableRefObject, useEffect, useRef, useState } from "react";
import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import Landing from "./components/pages/LandingPage/Landing";
import About from "./components/pages/About/About";
import Services from "./components/pages/Services/Services";
import Dental from "./components/pages/Dental/Dental";
import Blog from "./components/pages/Blog/Blog";
import BlogSingle from "./components/pages/BlogSingle/BlogSingle";
import Appointment from "./components/pages/Appointment/Appointment";
import Page from "./components/pages/Page/Page";
import ContactUs from "./components/pages/ContactUs/ContactUs";
import MedicalExperts from "./components/pages/MedicalExperts/MedicalExperts";
import { fetchToken, getMerchantDetails, getBackgroundImages, getTestimonies, getBlogPosts, getProducts, getServices, fetchCategoriesTree } from "./components/utils/api-service";
import { useGlobalDispatch, useGlobalState } from "./redux/globalState";
import Header from "./components/common/misc/Header";
import Footer from "./components/common/misc/Footer";

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  const { isLoading, merchantDetails } = useGlobalState();
  const dispatch = useGlobalDispatch();

  useEffect(() => {
    const fetchData = async () => {


      let tok = localStorage.getItem("_t");
      if (tok) {
        dispatch({ type: 'SET_TOKEN', payload: tok });
      } else {
        const token = await fetchToken();
        localStorage.setItem("_t", token);
        dispatch({ type: 'SET_TOKEN', payload: token });
      }

      const merchantDetails = await getMerchantDetails(tok);
      dispatch({ type: 'SET_MERCHANT_DETAILS', payload: merchantDetails });

      const backgroundImages = await getBackgroundImages(tok);
      dispatch({ type: 'SET_BACKGROUND_IMAGES', payload: backgroundImages });

      const testimonies = await getTestimonies(tok);
      dispatch({ type: 'SET_TESTIMONIES', payload: testimonies });

      const blogPosts = await getBlogPosts(tok);
      dispatch({ type: 'SET_BLOG_POSTS', payload: blogPosts });

      const products = await getProducts(tok);
      dispatch({ type: 'SET_PRODUCTS', payload: products });

      const services = await getServices(tok);
      dispatch({ type: 'SET_SERVICES', payload: services });

      const cats = await fetchCategoriesTree(tok);
      dispatch({ type: 'SET_CATEGORIES', payload: cats });



    };

    fetchData();

    return () => {
    }
  }, [dispatch]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "Home";
        metaDescription = "";
        break;
      case "/about":
        title = "About";
        metaDescription = "";
        break;
      case "/services":
        title = "Services";
        metaDescription = "";
        break;
      case "/dental":
        title = "Dental";
        metaDescription = "";
        break;
      case "/blogs":
        title = "Blogs";
        metaDescription = "";
        break;
      case "/blog-single":
        title = "Blog";
        metaDescription = "";
        break;
      case "/appointment":
        title = "Appointments";
        metaDescription = "";
        break;
      case "/404-page":
        title = "404-Not Found";
        metaDescription = "";
        break;
      case "/contact-us":
        title = "Contact Us";
        metaDescription = "";
        break;
      case "/medical-experts":
        title = "Medical Experts";
        metaDescription = "";
        break;
    }

    document.title = merchantDetails.name + " | " + title;


    if (metaDescription) {
      const metaDescriptionTag: HTMLMetaElement | null = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname, isLoading, merchantDetails]);

  const Loader = ({ isLoading }: any) => {
    if (!isLoading) return null;

    return (
      <div className="loader-overlay">
        <div className="loader"></div>
      </div>
    );
  };

  return (
    <div>
      <div>
        <Loader isLoading={isLoading} />
      </div>
      <div>
        <Header />
        <div >

          <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="/about" element={<About />} />
            <Route path="/services" element={<Services />} />
            <Route path="/dental" element={<Dental />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog-single" element={<BlogSingle />} />
            <Route path="/appointment" element={<Appointment />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/medical-experts/:id" element={<MedicalExperts />} />
            <Route path="/404-page" element={<Page />} />
            <Route path="/*" element={<Page />} />
          </Routes>
        </div>

        <Footer />
      </div>


    </div>

  );
}
export default App;
