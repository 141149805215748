import { FunctionComponent, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Component1 from "../../common/misc/Component1";
import "./Services.css";
import "../LandingPage/Landing.css";
import { useGlobalState } from "../../../redux/globalState";
import { getServices } from "../../utils/api-service";
import TextWithTags from "../../common/misc/TextWithTags";

const Services: FunctionComponent = () => {
  const navigate = useNavigate();
  const {services, categories}= useGlobalState();

  const onContainerClick = useCallback(() => {
    navigate("/medical-experts");
  }, [navigate]);

  function removeTags(input: string) {
    // Create a temporary DOM element to leverage the browser's HTML parsing capabilities
    var tempDiv = document.createElement("div");
    // Assign the input string as the innerHTML of the temporary element
    tempDiv.innerHTML = input;
    // Use the textContent property to extract and return the text without HTML tags
    return tempDiv.textContent || tempDiv.innerText || "";
  }

  
  return (
    <div className="services4">
      <div className="banner">
        <img className="image-icon" alt="" src="/photo@2x.png" />
        <div className="image-text">

          <div className="img-subtext-two">
            Services
          </div>
          <div className="img-subtext-three">
            Home {'>'} Services
          </div>
          <div>

          </div>
        </div>
      </div>
      <div className="heading">

<div className="departments">Services</div>

 
<div className="service-cards-container">
      {services.map((item, index) => (
        <div className="service-card" key={index}>
          <div className="image-services">
            {item?.imageUrl && <img src={item.imageUrl} alt="Service" />}
          </div>
          <div className="info-card-services">
            <div className="title-service-card">{item.name}</div>
            <div className="desc-service-card">
              {item.description.length > 200 ? (
                <TextWithTags text={removeTags(item.description).slice(0, 200) + "..."} />
              ) : (
                <TextWithTags text={item.description} />
              )}
            </div>
            <button className="readmore-service-card" onClick={()=>window.open("/service/" + item._id, "_self")}>Read More</button>
          </div>
        </div>
      ))}
    </div>

</div>

<div className="banner">
          <img className="image-icon" alt="" src="/time.jpg" />

         
        </div>

        <div className="team-container">



<div className="text9">
  <div className="heading3">

    <div className="medical-experts">{`MEDICAL EXPERTs `}</div>

    <div className="title2">
      <div className="the-professional-doctors">
        The Professional Doctors
      </div>
    </div>
  </div>
</div>

<div className="team-content">
  <div className="team-card" onClick={onContainerClick}>
    <div className="image1">
      <img className="image-icon5" alt="Dr. M Shylaja" src="https://s3-ap-southeast-1.amazonaws.com/content-ewns/images/productimages/small/5d826ffe8d5dbc18ecfd4711.png" />
    </div>
    <div className="text10">
      <div className="dr-helen-wilmore">Dr. M Shylaja</div>
      <div className="dental-patient4">Dental Hospital</div>
    </div>
    <div className="follow">
      <img className="insta-icon" alt="" src="/insta.svg" />
      <img className="fb-icon" alt="" src="/fb.svg" />
      <img className="twiter-icon" alt="" src="/twiter.svg" />
    </div>
  </div>
  <div className="team-card">
    <div className="image1">
      <img className="image-icon5" alt="" src="https://s3-ap-southeast-1.amazonaws.com/content-ewns/images/productimages/small/5bd1f90b8d5dbc46d0d16ac5.png" />
    </div>
    <div className="text10">
      <div className="dr-helen-wilmore">Dr. M. Venkat Reddy</div>
      <div className="dental-patient4">Dental Hospital</div>
    </div>
    <div className="follow">
      <img className="insta-icon" alt="" src="/insta.svg" />
      <img className="fb-icon" alt="" src="/fb1.svg" />
      <img className="twiter-icon" alt="" src="/twiter1.svg" />
    </div>
  </div>

</div>


<div className="line4" />


<div className="counter-content">
  <Component1 prop="25" years="Years " experience="Experience" />
  <Component1
    prop="893"
    years="Medicament"
    experience=" Invented
"
    propWidth="161.9px"
  />
  <Component1
    prop="75"
    years="Awards"
    experience="Winned"
    propWidth="116.4px"
  />
  <Component1
    prop="673 k"
    years="Happy"
    experience=" Clients"
    propWidth="118.5px"
  />
  <Component1
    prop="751"
    years="Pharmacies"
    experience=" Partners"
    propWidth="160.4px"
  />
</div>


</div>
    </div>
  );
};

export default Services;
