import React from 'react';

interface TextWithTagsProps {
  text: string;
}

const TextWithTags: React.FC<TextWithTagsProps> = ({ text }) => {
  return (
    <div dangerouslySetInnerHTML={{ __html: text }} />
  );
};

export default TextWithTags;